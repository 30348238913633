import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataChannels } from 'src/app/shared/models/data-channels.enum';

@Injectable({
  providedIn: 'root',
})
export class DataMessagingService {
  private messages: { event: DataChannels; value: object }[] = [];

  constructor() {}

  channel(channelName: DataChannels): Observable<object> {
    if (!this.messages[channelName]) {
      this.messages[channelName] = new BehaviorSubject('');
    }

    return this.messages[channelName].asObservable();
  }

  publish(channelName: DataChannels, message: object): void {
    if (!this.messages[channelName]) {
      this.messages[channelName] = new BehaviorSubject('');
    }

    this.messages[channelName].next(message);
  }
}
