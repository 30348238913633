import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MembershipIconComponent } from './game/membership-icon/membership-icon.component';
import { BoardComponent } from './game/board/board.component';
import { AvatarIconComponent } from './game/avatar-icon/avatar-icon.component';
import { PlayerComponent } from './game/player/player.component';
import { CreateComponent } from './game/create/create.component';
import { GameService } from './services/game/game.service';
import { LobbyComponent } from './game/lobby/lobby.component';
import { AgentNameComponent } from './game/agent-name/agent-name.component';
import { CreateAgentComponent } from './game/create-agent/create-agent.component';
import { PageHeaderComponent } from './game/page-header/page-header.component';

@NgModule({
  declarations: [
    AppComponent,
    MembershipIconComponent,
    BoardComponent,
    AvatarIconComponent,
    PlayerComponent,
    CreateComponent,
    LobbyComponent,
    AgentNameComponent,
    CreateAgentComponent,
    PageHeaderComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, ReactiveFormsModule],
  providers: [GameService],
  bootstrap: [AppComponent],
})
export class AppModule { }
