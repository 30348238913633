import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-membership-icon',
  templateUrl: './membership-icon.component.html',
  styleUrls: ['./membership-icon.component.css']
})
export class MembershipIconComponent implements OnInit {
  @Input() isTeamMember: boolean;
  @Output() teamSelectionChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.isTeamMember = !this.isTeamMember;
    this.teamSelectionChange.emit(this.isTeamMember);
  }

}
