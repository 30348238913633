<main class="wrapper">
  <section class="hero">
    <h1>
      Dystopia
      <p>The empire must fall. You must succeed.<br /></p>
    </h1>
  </section>

  <section class="desc">
    <article>
      <p>
        Join a small group of freedom fighters in their push to overthrow a
        powerful and corrupt government. Embark on 5 missions to destroy their
        bases and advance the cause of freedom. Can you succeed before their
        spies destroy your plans?
      </p>
      <p>
        A game for 5 - 10 players
      </p>
    </article>
  </section>

  <section class="actionRow">
    <div class="actionItem">
      <p>
        Gather your friends and go on missions to fight the oppression.
      </p>
      <div class="actionButton">
        <div class="slant-button">
          <button (click)="createGame()">Create New Game</button>
        </div>
      </div>
    </div>

    <div class="actionItem">
      <p>
        Join an existing cell of freedom fighters in their fight against
        tyranny.
      </p>
      <div class="actionLink">
        <p>
          <input type="text" placeholder="Enter Game Code" />
        </p>
        <div class="slant-button">
          <button (click)="joinGame()">Join Game</button>
        </div>
      </div>
    </div>
  </section>
</main>