import { Component, OnInit } from '@angular/core';
import { Player } from '../../shared/models/player.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css'],
})
export class BoardComponent implements OnInit {
  gameId: string;
  currentUser: string;

  // players: Player[] = [
  //   {
  //     name: 'Daren',
  //   },
  //   {
  //     name: 'Amy',
  //   },
  //   {
  //     name: 'David',
  //   },
  //   {
  //     name: 'Kelly',
  //   },
  //   {
  //     name: 'Matthew',
  //   },
  //   {
  //     name: 'Pete',
  //   },
  // ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.gameId = params['gameId'];
    });

    this.currentUser = sessionStorage.getItem(this.gameId);
  }

  onPlayerChanged(updatedPlayer: Player) {
    // const playerIndex = this.players.findIndex(p => p.name === updatedPlayer.name);
    // this.players[playerIndex] = updatedPlayer;
    // console.log(`Players: ${JSON.stringify(this.players)}`);
  }

  setName(firstName: string) {
    sessionStorage.setItem(this.gameId, firstName);
    this.currentUser = sessionStorage.getItem(this.gameId);
  }
}
