<svg (click)="onClick()" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280.000000 640.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
    </metadata>
    <g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)" stroke="none">
        <path [ngClass]="isTeamMember ? 'active' : ''" d="M4020 4900 c-47 -4 -102 -12 -122 -19 -37 -12 -88 -61 -88 -85 0 -19
-124 -314 -155 -369 -37 -66 -56 -76 -131 -74 l-63 2 -18 -75 -19 -75 -1673
-2 c-1358 -2 -1676 -4 -1688 -15 -9 -7 -26 -33 -37 -58 -20 -42 -21 -60 -19
-305 3 -342 20 -823 34 -955 13 -122 25 -150 81 -190 80 -58 185 -41 628 105
140 46 302 99 360 118 58 19 170 58 250 87 80 28 224 78 320 110 96 32 267 91
380 130 113 40 248 87 300 105 118 41 322 91 449 111 53 8 155 24 226 36 171
27 346 36 408 21 67 -16 133 -58 168 -106 30 -41 31 -45 27 -129 -3 -72 -9
-96 -34 -142 -27 -51 -161 -210 -526 -627 -147 -167 -160 -194 -115 -223 23
-16 415 -154 514 -182 90 -26 127 -28 163 -9 31 16 32 18 27 68 -3 29 -8 64
-12 78 -7 27 19 75 261 479 l99 165 43 3 c33 2 42 0 42 -12 0 -13 13 -16 65
-16 57 0 69 3 91 25 l26 26 266 -4 c147 -3 284 -8 305 -12 l37 -7 0 -133 c0
-109 37 -520 54 -600 3 -11 10 -49 17 -85 25 -137 96 -379 146 -498 19 -45 30
-61 42 -58 9 2 187 46 396 96 209 51 386 94 394 96 19 6 10 73 -22 159 -32 88
-78 275 -128 520 -25 126 -56 376 -65 520 l-7 114 29 7 c16 4 37 11 48 16 17
9 16 12 -9 39 l-28 28 2 208 c2 157 5 211 15 221 8 8 42 17 76 21 59 7 79 20
80 49 0 6 11 12 25 12 22 0 25 -4 25 -36 0 -25 4 -35 13 -31 6 2 51 16 100 31
48 15 87 31 87 36 0 6 11 10 25 10 21 0 25 -5 25 -30 0 -17 7 -44 15 -60 33
-64 -111 -60 1945 -60 1849 0 1870 0 1890 20 11 11 22 31 26 44 5 21 10 24 33
20 28 -6 38 7 70 89 l11 27 122 0 c68 0 140 -3 161 -6 l37 -7 0 -71 c0 -113 2
-116 71 -116 54 0 59 2 59 21 0 19 4 21 39 16 32 -4 43 -1 60 17 26 28 27 77
3 99 -10 10 -33 17 -51 17 -21 0 -30 4 -25 11 5 9 200 10 748 5 l741 -7 26
-30 c31 -35 42 -36 61 -7 l15 22 12 -22 c15 -28 57 -30 64 -2 7 26 37 26 44 0
6 -24 38 -27 47 -4 5 14 26 16 168 13 l162 -4 8 40 c5 22 7 78 6 125 l-3 85
-162 5 c-110 3 -166 9 -175 17 -17 18 -45 16 -52 -2 -7 -19 -32 -19 -40 0 -7
20 -43 19 -63 0 -14 -14 -17 -14 -32 0 -24 21 -39 19 -64 -10 l-22 -26 -735
12 c-700 11 -736 12 -755 31 -19 18 -20 32 -20 357 0 365 0 364 -54 376 -66
14 -140 -46 -316 -260 -27 -33 -72 -85 -99 -115 -27 -30 -58 -75 -69 -99 -19
-43 -20 -44 -36 -24 -9 11 -26 26 -37 32 -31 15 -201 29 -579 46 -181 8 -460
22 -620 30 -420 21 -704 34 -1010 45 -355 13 -724 28 -965 40 -419 20 -585 23
-608 11 -28 -15 -52 -61 -52 -101 0 -25 -4 -30 -24 -30 -13 0 -26 5 -28 11 -3
10 -98 44 -172 63 -23 6 -25 3 -28 -31 -2 -31 -7 -39 -25 -41 -23 -3 -23 -3
-23 135 0 162 -13 220 -63 279 -66 77 -50 73 -857 173 -404 51 -764 96 -800
102 -36 5 -74 11 -85 13 -11 2 -58 0 -105 -4z m6494 -512 c10 -14 16 -44 16
-80 l0 -58 35 0 35 0 0 -81 c0 -125 10 -119 -195 -119 -144 0 -176 3 -189 16
-40 39 -16 84 116 225 122 128 150 143 182 97z m-8 -452 c8 -3 14 -15 14 -26
0 -19 -6 -20 -140 -20 -115 0 -140 3 -140 14 0 32 35 41 145 39 59 -1 113 -4
121 -7z m-6409 -668 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z" />
    </g>
</svg>