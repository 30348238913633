import { Component, OnInit } from '@angular/core';
import { GameService } from 'src/app/services/game/game.service';
import { Router } from '@angular/router';
import { Game } from 'src/app/shared/models/game.model';
import { UserSession } from 'src/app/shared/models/user-session.model';
import { DataMessagingService } from 'src/app/services/dataMessaging/data-messaging.service';
import { DataChannels } from 'src/app/shared/models/data-channels.enum';
import { SessionService } from 'src/app/services/session-service/session.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent implements OnInit {
  constructor(
    private router: Router,
    private messagingService: DataMessagingService,
    private gameService: GameService,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.messagingService
      .channel(DataChannels.GameCreated)
      .subscribe((game: Game) => {
        if (game) {
          console.log(`Game: ${JSON.stringify(game)}`);
          this.saveSession(game);
          this.router.navigate([`/game`]);
        }
      });
  }

  createGame(): void {
    this.gameService.createGame();
  }

  joinGame(): void {
    //this.gameService.updateGame();
  }

  private saveSession(game: Game): void {
    const userSession: UserSession = {
      player: game.players[0],
      game: game,
    };

    this.sessionService.saveSession(userSession);
  }
}
