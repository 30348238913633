import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { SessionService } from 'src/app/services/session-service/session.service';
import { UserSession } from '../../models/user-session.model';

@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService, private injector: Injector) { }

  // if session doesn't have a gameId then go to create game / join game page
  canActivate(): boolean | UrlTree {
    return this.allowAccess();
  }

  private allowAccess(): boolean | UrlTree {
    const userSession: UserSession = this.sessionService.getSession();

    console.log(`User Session: ${JSON.stringify(userSession)}`);

    if (!userSession || !userSession.game || !userSession.game.gameId) {
      console.log(`Game ID not found.  Redirecting to /create`);
      return this.router.parseUrl('/create');

    } else if (!userSession || !userSession.player || !userSession.player.name) {
      console.log(`Agent name not found.  Redirecting to /create-agent`);
      return this.router.parseUrl('/create-agent');

    } else if (!userSession || !userSession.game || !userSession.game.rules || userSession.game.rules.length < 1) {
      console.log(`Game hasn't started yet.  Redirecting to /lobby`);
      return this.router.parseUrl('/lobby');

    } else {
      return false;
    }

  }
}
