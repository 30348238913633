import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoardComponent } from './game/board/board.component';
import { CreateComponent } from './game/create/create.component';
import { LobbyComponent } from './game/lobby/lobby.component';
import { GameGuard } from './shared/guards/game/game.guard';
import { CreateAgentComponent } from './game/create-agent/create-agent.component';

const routes: Routes = [
  { path: '', component: CreateComponent },
  { path: 'create', redirectTo: '', pathMatch: 'full' },
  {
    path: 'create-agent',
    component: CreateAgentComponent,
  },
  {
    path: 'lobby',
    component: LobbyComponent,
  },
  {
    path: 'game',
    component: BoardComponent,
    canActivate: [GameGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
