<div *ngIf="!currentUser">
  <p>Enter First Name:</p>
  <input #firstName type="text" />
  <button (click)="setName(firstName.value)">Save</button>
</div>

<!-- <div *ngIf="currentUser" class="flex-container">
    <div *ngFor="let player of players">
        <app-player [player]="player" (playerChange)="onPlayerChanged($event)"></app-player>
    </div>
</div> -->
