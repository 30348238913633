export const Avatars = [
  'F-4775455.png',
  'F-4775456.png',
  'F-4775457.png',
  'F-4775459.png',
  'F-4775460.png',
  'F-4775506.png',
  'F-4775516.png',
  'F-4775517.png',
  'F-4775521.png',
  'F-4775522.png',
  'F-4775523.png',
  'F-4775529.png',
  'F-4775530.png',
  'F-4775534.png',
  'F-4775535.png',
  'F-4775539.png',
  'F-4775540.png',
  'F-4775542.png',
  'M-4775501.png',
  'M-4775503.png',
  'M-4775504.png',
  'M-4775509.png',
  'M-4775511.png',
  'M-4775512.png',
  'M-4775519.png',
  'M-4775520.png',
  'M-4775524.png',
  'M-4775528.png',
  'M-4775532.png',
  'M-4775533.png',
  'M-4775536.png',
  'M-4775537.png',
  'M-4775541.png',
  'M-4775543.png',
  'M-4775544.png',
  'M-4775546.png'
];