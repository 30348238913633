<app-page-header></app-page-header>

<div class="page-flex-container">
  <h3>Players</h3>

  <div class="page-flex-item">
    <div *ngIf="game">
      <div *ngFor="let player of game.players">
        <img class="avatar" src="{{ 'assets/avatars/' + player.avatar }}" width="128px" />
        <span>{{ player.name }}</span>
      </div>
    </div>
  </div>

  <div class="page-flex-item">
    <h3>Rules</h3>

    <h5>Objective</h5>
    Working as a group you must complete 3 out of 5 missions.

    <h5>Game Setup</h5>

    <ul>
      <li>Each mission will have a Team Lead. To start the game, the Team Lead is randomly assigned.</li>
      <li>
        Each player will be randomly assigned to one of two roles.<br />
        <p>Agent</p>
        <p>
          A freedom fighter dedicated to overthrowing the dystopian government
        </p>

        <p>Spy</p>
        <p>
          A member of the dystopian government sent to subvert the plans of the freedom
          fighters. Spys must NEVER admit to being a spy no matter how strong the evidence.
        </p>
      </li>
    </ul>

    <h5>Game Play</h5>
    <ol>
      <li>
        Team Selection
        <ul>
          <li>The Team Lead selects the agents they want to go on each mission. Only some players will be selected for a
            mission. The Team Lead does not go on the mission unless they select themselves.</li>
          <li>Everyone votes on whether they approve of the selected team or not.</li>
          <li>If the majority of the agents approve then the selected agents are a part of the mission</li>
          <li>If the majority of the agents disapprove then a new player is assigned as Team Lead and the team selection
            process starts over.</li>
        </ul>
      </li>

      <li>
        The mission
        <ul>
          <li>Once a team has been approved those team members secretly vote on whether the missions should succeed or
            fail.
          </li>
          <li>As an agent trying to overthrow the dystopian government you MUST vote for every mission to succeed.</li>
          <li>As a spy trying to disrupt the rebels you may choose for the mission to Succeed or Fail based on your own
            strategy.</li>
          <li>Most missions fail if just one player votes for the mission to fail. Therefore, it is imperative that you
            do
            not put any spies on the team. Some missions require 2 votes to fail.</li>
        </ul>
      </li>
    </ol>
  </div>

  <div class="page-flex-item">
    <h3>Actions</h3>

  </div>
</div>