<app-page-header></app-page-header>

<form (ngSubmit)="saveAgent()" [formGroup]="agentForm">
  <div class="grid-container">
    <div>
      <div class="slant-button toggle" [ngClass]="gender === 'M' ? 'active' : ''">
        <button type="button" (click)="setGender('M');">Male</button>
      </div>
    </div>

    <div>
      <div class="slant-button toggle" [ngClass]="gender === 'F' ? 'active' : ''">
        <button type="button" (click)="setGender('F')">Female</button>
      </div>
    </div>

  </div>


  <div class="slides">
    <div *ngFor="let icon of avatars; let i = index;">
      <input #avatar type="radio" name="agentAvatar" id="{{ getImageName(i) }}" [attr.data-icon]="icon" checked />
      <div class="slide-container">
        <div class="slide" style="text-align: center;">
          <img class="avatar" src="{{ 'assets/avatars/' + icon }}" />
          <p>{{ 'Agent # ' + (this.avatars.length - i).toString() }}</p>
        </div>
        <div class="nav">
          <label for="{{ getPreviousImageName(avatars.length, i) }}" class="prev">&#x2039;</label>
          <label for="{{ getNextImageName(avatars.length, i) }}" class="next">&#x203a;</label>
        </div>
      </div>
    </div>
  </div>

  <div class="under-slides">
    <div class="panel-center">
      <input type="text" placeholder="Enter Agent Name" formControlName="agentName" />
      <p class="input-hint">Your agent name should match the name you are using on your chat platform</p>
    </div>

    <div class="slant-button">
      <button type="submit" (click)="agentNameControl.markAsTouched();">Select Agent</button>
    </div>

  </div>

</form>