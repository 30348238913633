import { Injectable } from '@angular/core';
import { UserSession } from 'src/app/shared/models/user-session.model';
import { SessionKeys } from 'src/app/shared/models/session-keys.enum';
import { DataMessagingService } from '../dataMessaging/data-messaging.service';
import { DataChannels } from 'src/app/shared/models/data-channels.enum';
import { Game } from 'src/app/shared/models/game.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionLengthInHours = 2;

  constructor(private messagingService: DataMessagingService) {
    this.messagingService.channel(DataChannels.LatestGame).subscribe((g: Game) => {
      const session = this.getSession();

      if (session) {
        session.game = g;
        this.saveSession(session);
      }
    });
  }

  getSession(): UserSession {
    let session: UserSession;
    const currentDateTime = new Date();
    const item = localStorage.getItem(SessionKeys.UserSession);

    if (item) {
      session = JSON.parse(item);
      if (session.expiration > currentDateTime) {
        console.log(`Session expired. ${session.expiration} > ${currentDateTime.getMilliseconds()}`);
        localStorage.removeItem(SessionKeys.UserSession);
      }
      else {
        return session;
      }
    }

    return null;
  }

  saveSession(session: UserSession): void {
    const expiration: Date = new Date();
    expiration.setTime(expiration.getTime() + (this.sessionLengthInHours * 60 * 60 * 1000));

    session.expiration = expiration;
    localStorage.setItem(SessionKeys.UserSession, JSON.stringify(session));
  }
}
