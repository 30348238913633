import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Player } from 'src/app/shared/models/player.model';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
})
export class PlayerComponent implements OnInit {
  @Input() player: Player;
  @Output() playerChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onTeamSelectionChanged(newValue) {
    // this.player.teamMember = newValue;
    // console.log(`Player: ${JSON.stringify(this.player)}`);
    // this.playerChange.emit(this.player);
  }
}
