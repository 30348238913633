import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { DataMessagingService } from '../dataMessaging/data-messaging.service';
import { DataChannels } from 'src/app/shared/models/data-channels.enum';
import { GameActions } from 'src/app/shared/models/game.actions.enum';
import { SocketMessage } from 'src/app/shared/models/socket-message.model';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  _socket: WebSocketSubject<SocketMessage>;
  webSocketUrl: string = environment.webSocketUrl;

  constructor(private messagingService: DataMessagingService) { }

  private socket(): WebSocketSubject<SocketMessage> {
    if (!this._socket || this._socket.closed) {
      this._socket = webSocket(this.webSocketUrl);
      this.subscribe();
    }
    return this._socket;
  }

  private subscribe(): void {
    this.socket().subscribe(
      (socketResponse: SocketMessage) => {
        this.publishResponse(socketResponse);
      },
      (err) => {
        // Called if WebSocket API signals some kind of error
        console.log(JSON.stringify(err));
        this.messagingService.publish(DataChannels.ErrorReceived, err);
      },
      () => {
        // Called when connection is closed (for whatever reason)
        console.log('complete');
        this.messagingService.publish(DataChannels.WebSocketClosed, null);
      }
    );
  }

  disconnect(): void {
    this.socket().unsubscribe();
    this.socket().complete();
  }

  send(message: SocketMessage): void {
    this.socket().next(message);
  }

  private publishResponse(message: SocketMessage): void {
    switch (message.action) {
      case GameActions.CreateGame:  // Replies to creator only
        console.log(`GameCreated: ${JSON.stringify(message)}`);
        this.messagingService.publish(DataChannels.GameCreated, message.data);
        this.messagingService.publish(DataChannels.LatestGame, message.data);
        break;
      case GameActions.GetGame:     // Replies to requestor only
        console.log(`GameRetrieved: ${JSON.stringify(message)}`);
        this.messagingService.publish(DataChannels.GameRetrieved, message.data);
        this.messagingService.publish(DataChannels.LatestGame, message.data);
        break;
      case GameActions.UpdateGame:
        console.log(`GameUpdated: ${JSON.stringify(message)}`);
        this.messagingService.publish(DataChannels.GameUpdated, message.data);
        this.messagingService.publish(DataChannels.LatestGame, message.data);
        break;
      default:
        console.log(`MessageReceived: ${JSON.stringify(message)}`);
        break;
    }
  }
}
