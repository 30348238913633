import { Component, OnInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Avatars } from '../../shared/models/avatars';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { SessionService } from 'src/app/services/session-service/session.service';
import { GameService } from 'src/app/services/game/game.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',
  styleUrls: ['./create-agent.component.css']
})
export class CreateAgentComponent implements OnInit {
  @ViewChildren("avatar") avatarInputs: QueryList<ElementRef>;
  agentForm: FormGroup;
  agentNameControl: AbstractControl;
  avatars: string[];
  gender = "M";

  constructor(private router: Router, private fb: FormBuilder, private sessionService: SessionService, private gameService: GameService) {

  }

  ngOnInit(): void {
    this.agentForm = this.fb.group({
      agentName: ['', [Validators.required]]
    });

    this.agentNameControl = this.agentForm.get('agentName');
    this.avatars = this.shuffle(Avatars.filter((a) => a.startsWith(this.gender)));
  }

  setGender(gender: string) {
    this.gender = gender;
    this.avatars = this.shuffle(Avatars.filter((a) => a.startsWith(this.gender)));
  }

  getImageName(i: number): string {
    return 'img-' + i.toString();
  }

  getAvatarName(length: number, i: number): string {
    const agentNumber = length - i;
    return `Agent # ${agentNumber}`;
  }

  getPreviousImageName(length: number, i: number): string {
    const next = i === length - 1 ? 0 : i + 1;
    return 'img-' + next.toString();
  }

  getNextImageName(length: number, i: number): string {
    const prev = i === 0 ? length - 1 : i - 1;
    return 'img-' + prev.toString();
  }

  saveAgent() {
    if (this.agentForm.valid) {
      const avatar = this.avatarInputs.find((av) => av.nativeElement.checked).nativeElement.getAttribute("data-icon");

      const userSession = this.sessionService.getSession();
      userSession.player.avatar = avatar;
      userSession.player.name = this.agentNameControl.value;

      const playerIndex = userSession.game.players.findIndex((p) => p.connectionId === userSession.player.connectionId);
      userSession.game.players.splice(playerIndex, 1, userSession.player);

      this.sessionService.saveSession(userSession);
      this.gameService.updateGame(userSession.game);
      this.router.navigate([`/game`]);
    }
  }

  private shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

}
