import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-name',
  templateUrl: './agent-name.component.html',
  styleUrls: ['./agent-name.component.css']
})
export class AgentNameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
