import { Component, OnInit } from '@angular/core';
import { DataMessagingService } from 'src/app/services/dataMessaging/data-messaging.service';
import { DataChannels } from 'src/app/shared/models/data-channels.enum';
import { Game } from 'src/app/shared/models/game.model';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session-service/session.service';
import { GameService } from 'src/app/services/game/game.service';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.css']
})
export class LobbyComponent implements OnInit {
  game: Game;

  constructor(private router: Router, private messagingService: DataMessagingService, private sessionService: SessionService, private gameService: GameService) { }

  ngOnInit(): void {
    console.log(`ngOnInit`);
    this.messagingService
      .channel(DataChannels.LatestGame)
      .subscribe((game: Game) => {
        console.log("Subscribed");
        if (game) {
          console.log(`Found game: ${JSON.stringify(game)}`);
          this.game = game;
        }
      });

    // const userSession = this.sessionService.getSession();

    // console.log(`ngOnInit: ${userSession.game.gameId}`);

    // this.gameService.getGame(userSession.game.gameId);
  }

}
