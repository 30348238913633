import { Injectable } from '@angular/core';
import { SocketService } from '../socket/socket.service';
import { Game } from 'src/app/shared/models/game.model';
import { GameActions } from 'src/app/shared/models/game.actions.enum';
import { SocketMessage } from 'src/app/shared/models/socket-message.model';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  constructor(private socketService: SocketService) {}

  createGame(): void {
    const msg: SocketMessage = {
      action: GameActions.CreateGame,
    };

    this.socketService.send(msg);
  }

  getGame(gameId: string): void {
    const msg: SocketMessage = {
      action: GameActions.GetGame,
      data: gameId,
    };

    this.socketService.send(msg);
  }

  updateGame(game: Game): void {
    const msg: SocketMessage = {
      action: GameActions.UpdateGame,
      data: game,
    };

    this.socketService.send(msg);
  }
}
